.selected-plan {
    /* border: 2px !important; */
    border: 1px solid var(--primary-color) !important;
    /* border-color: var(--primary-color) !important; */
    /* margin-top: 5rem !important; */
    /* margin-bottom: 5rem !important; */
    background-color: var(--primary-color-super-light) !important;
}

.selected-plan-card {
    /* padding-top: 10rem !important; */
}

.current-plan {
    border: 1px solid var(--primary-color) !important;
    background-color: var(--primary-color-ultra-light) !important;
    border-radius: var(--border-radius) !important;
    padding: 0.5rem !important;
}