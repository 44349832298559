.fte-box {
    border-radius: var(--border-radius) !important;
    background-color: var(--primary-color-ultra-light) !important;
    width: 58.3333% !important;
    min-height: 9rem !important;
    border: 1px solid var(--primary-color-extra-light) !important;
}

.overhead-label {
    font-weight: 600 !important;
    color: var(--primary-color) !important;
    text-align: center !important;
}