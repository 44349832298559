.add-participant-button.p-button {
    border: 1px dashed rgba(145, 158, 171, 0.2) !important;
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
}

.custom-sidebar.p-sidebar {
    width: 100%;
    // @media (min-width: 600px) {
    //     width: 480px !important;
    //     max-width: 480px !important;
    // }
   
    @media (min-width: 1024px) {
        width: 75vw !important;
        max-width: 75vw !important;
    }
    @media (min-width: 1200px) {
        width: 50vw !important;
        max-width: 50vw !important;
    }
    @media (min-width: 1445px) {
        width: 40vw !important;
        max-width: 40vw !important;
    }
    
    
    
}

// .custom-sidebar.p-sidebar .p-sidebar-header + .p-sidebar-content {
    // overflow: hidden !important;
// }

.custom-sidebar .p-calendar .p-inputtext {
    border: 1px transparent;
}

.custom-sidebar-footer {
    width: 100%;
    @media (min-width: 768px) {
        max-width: 480px;
        min-width: 480px;
    }
}

.custom-sidebar-footer .custom-comment-editor .p-editor-container .p-editor-content{
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border: none;
}

.custom-sidebar-footer .custom-comment-editor .p-editor-container .p-editor-content .ql-editor {
    max-height: 50px;
    min-height: 50px;
    align-items: start;
    justify-content: start;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.custom-comment-editor .ql-toolbar.ql-snow  {
    border: none !important;
    padding: 0px;
}
.custom-comment-editor {
    padding: 0;
    padding-bottom: 10px;
}

.custom-comment-box {
    border: 1px solid #ced4da;
    overflow: hidden;
    border-radius: var(--border-radius);
    min-height: 100%;
}

.custom-comments-box img {
    max-width: 100% !important;
    border-radius: var(--border-radius-large);
}

.custom-description-editor {
    width: 100%;
    @media (max-width: 600px) {
        width: 98vw !important;
        max-width: 98vw !important;
    }

    @media (min-width: 600px) {
        width: 80vw !important;
        max-width: 80vw !important;
    }
   
    @media (min-width: 1024px) {
        width: 60vw !important;
        max-width: 60vw !important;
    }
    @media (min-width: 1200px) {
        width: 40vw !important;
        max-width: 40vw !important;
    }
    @media (min-width: 1445px) {
        width: 32vw !important;
        max-width: 32vw !important;
    }
}