.custom-metric-selector .p-datepicker{
    padding: 0 !important;
    border: none !important;
    /* min-width: 100% !important; */
}
.p-datepicker-title .p-link{
    color: var(--secondary-color-extra-light);
}

.p-datepicker table td > span {
    border-radius: 15% !important;
}

.p-datepicker .p-datepicker-header {
    border-bottom: none !important;
    padding-bottom: 0 !important;
}

.custom-metric-overlay {
    @media (min-width: 1024px) {
        min-width: 550px !important;
    }
    // @media (min-width: 1200px) {
    //     min-width: 550px !important;
    // }
    // @media (min-width: 1024px) {
    //     min-width: 480px !important;
    // }
    // @media (min-width: 1024px) {
    //     min-width: 480px !important;
    // }
    // @media (min-width: 1024px) {
    //     min-width: 480px !important;
    // }
}