.custom-concepts-table td {
    vertical-align: top !important;
}
.project-title {
    border: 1px solid transparent;
    padding: 0.5rem !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}
.project-title:hover {
    border: 1px solid var(--primary-color);
    border-radius: var(--border-radius);
    padding: 0.5rem !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}