.custom-text-area {
    border: 1px solid transparent !important;
    padding-left: 0 !important;
    /* padding-bottom: 0 !important; */
}

.custom-text-area::-webkit-input-placeholder {
    font-style: italic;
    font-size: 24px !important;
 }
.custom-text-area:-moz-placeholder {
    font-style: italic;
    font-size: 24px !important;
 }
.custom-text-area::-moz-placeholder {
    font-style: italic;
    font-size: 24px !important;
 }
.custom-text-area:-ms-input-placeholder {  
    font-style: italic;
    font-size: 24px !important;
 }