.custom-dialog .p-dialog-header {
    padding: 0 !important;
    border: none !important;
}
.custom-dialog .p-dialog-footer {
    /* padding: 0 !important; */
    border: none !important;
    padding: 0rem 0rem 1rem 0rem;
}

.custom-dialog.p-dialog {
    // max-height: calc(100% - 64px);
    max-width: 444px;
    width: calc(100% - 64px);
    max-height: 60% !important;
}

// @media (min-width: 600px) {
//     .custom-dialog.p-dialog {
//         max-height: 60% !important;
//     }
// }

.custom-button-dropdown.p-dropdown .p-dropdown-label {
    padding: 0;
    // display: none;
}
.custom-button-dropdown.p-dropdown .p-dropdown-trigger {
    display: none;
}
.custom-button-dropdown.p-dropdown {
 border: none;
}