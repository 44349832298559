.custom-invoice-settings-tooltip {
    max-width: 18rem !important;
}
// .p-tooltip .p-tooltip-text {
//     background-color: "white" !important;
// }

.custom-invoice-settings-tooltip.p-tooltip .p-tooltip-text {
    background: white !important;

    color: var(--secondary-color);
    font-size: 12px !important;
    font-weight: 300 !important;
    border: 1px solid var(--surface-300) !important;
}

.custom-invoice-settings-tooltip.p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: white !important;
}
.custom-invoice-settings-tooltip.p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: white !important;
    
}
.custom-invoice-settings-tooltip.p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: white !important;
}
.custom-invoice-settings-tooltip.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: white !important;
}
