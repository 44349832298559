.custom-tabs ul li {
    min-width: 15rem !important;
}

.custom-tabs ul li a{
    text-align: center !important;
    justify-content: center !important;
}

.custom-dropdown-without-hover .p-dropdown-items .p-dropdown-item:hover {
    background: none !important;
}

// .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
//     background: none !important;
// }