.templates-gallery-dialog .p-dialog-content{
    // border-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    padding: 0;
    overflow-x: hidden;
}
.templates-gallery-dialog .custom-card {
    background-color: var(--surface-card);
    // padding: 1rem;
    // margin-bottom: 1rem;
    border: 1px solid #ced4da;
    // border-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08) !important;
    overflow: hidden !important;
}

.templates-gallery-dialog .p-card:hover {
    border: 1px solid #ced4da !important;
}

.templates-gallery-dialog .p-card .p-card-content {
    padding-top: 0.1rem !important;
}

.templates-gallery-dialog .p-card .p-card-header:hover img{
    background-color: var(--background-block-ui) !important;
    opacity: 0.4 !important;
    // cursor: pointer;
    
}

.templates-gallery-dialog .p-card .p-card-header {
    // background-color: rgb(75, 69, 102, 0.7) !important;
    background: rgb(75, 69, 102, 0.7) !important;
}

.templates-gallery-dialog .p-blockui {
    background: none !important;
}