.custom-single-line-calendar .p-inputtext{
    border: none !important;
    border-radius: 0 !important;
    padding-right: 0.1rem !important;
    border-right: 1px solid #ced4da !important;
    border-left: 1px solid #ced4da !important;
    background: none !important;
    text-align: center !important;
}

.custom-calendar-box {
    border: 1px solid #ced4da;
    border-radius: var(--border-radius);
    margin: 0 !important;
    padding: 0 !important;
    background-color: var(--surface-a) !important;
}

.custom-incurred-hour-box {
    background: var(--secondary-color-mega-light);
    padding-left: 0.4rem !important;
    padding-right: 0.4rem !important;
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
    border-radius: var(--border-radius);
    text-align: center !important;
}