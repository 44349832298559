.circle {
    fill: none;
    stroke-width: 10px;
    // stroke-dasharray: calc(0.5 * calc(6.28319 * 139)) calc(6.28319 * 139);
}

.circle-bg {
    fill: none;
    stroke-width: 12px;
}

.icon-container {
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.icon-border svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
