.rbc-calendar .rbc-month-view {
    border-radius: var(--border-radius) !important;
    overflow: hidden !important;
}

.rbc-calendar .rbc-month-view .rbc-month-header .rbc-header {
    padding: 1rem !important;
    background-color: var(--miflow-no-white-v2-background);
    font-size: 14px !important;
    color: var(--secondary-color);
    font-weight: 600 !important;
}

.rbc-button-link {
    font-size: 14px !important;
    color: var(--secondary-color);
    font-weight: 500 !important;
}
.rbc-off-range-bg {
    background-color: var(--primary-color-fill-disabled) !important;
}


.rbc-calendar .rbc-row-segment {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}