// ################################################################# Scale - INI #########################################################################
.font-plus-size {
    font-size: 64px !important;    
}
.font-xxx-large {
    font-size: 48px !important;
}
.font-xx-large {
    font-size: 40px !important;
}
.font-x-large {
    font-size: 32px !important;
}
.font-large {
    font-size: 24px !important;
}
.font-medium {
    font-size: 20px !important;
}
.font-small {
    font-size: 16px !important;
}
.font-x-small {
    font-size: 14px !important;
}
.font-xx-small {
    font-size: 12px !important;
}
.font-xxx-small {
    font-size: 10px !important;
}
.font-super-small {
    font-size: 8px !important;
}
// ################################################################# Scale - END #########################################################################


// ################################################################# Text - INI #################################################################
.mousee-text {
    // color: var(--text-color);
    color: var(--secondary-color);
    // font-family: var(--font-family); // Is setted globally, if you set here the primereact-icons doesnt work
}
.mousee-text-small {
    color: var(--secondary-color-extra-light);
}

.mousee-text-large {
    color: var(--secondary-color-light);
}
// ################################################################# Text - END #################################################################

// ################################################################# Styles - INI #################################################################

.font-weight-bold {
    font-weight: 600 !important;
}

.font-weight-semibold {
    font-weight: 500 !important;
}

.font-weight-regular {
    font-weight: 400 !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-extralight {
    font-weight: 200 !important;
}
.font-weight-superlight {
    font-weight: 100 !important;
}
.font-weight-ultralight {
    font-weight: 50 !important;
}

// ################################################################# Styles - END #################################################################