
.task-title-text-input {
    border: none !important;
}

.task-title-text-input::placeholder {
    color: #B4B4C7 !important;
}

.task-title-text-input:focus {
    outline: none;
}

.custom-task-editor .p-editor-container .p-editor-toolbar{
    border-radius: 0 !important;
    border-right: none !important;
    border-left: none !important;
    // border: none !important;
    
}

.custom-task-editor .p-editor-container .p-editor-content {
    border-radius: 0 !important;
    border: none !important;
}

.custom-task-editor .p-editor-container .p-editor-content .ql-editor p{
    font-size: 14px !important;
    color: var(--secondary-color);
    font-weight: 400 !important;
}

.custom-task-editor .p-editor-container .p-editor-content .ql-editor::placeholder{
    font-size: 14px !important;
    color: var(--secondary-color) !important;
    font-weight: 400 !important;
}

// ::placeholder{
//     font-size: 14px !important;
//     color: var(--secondary-color) !important;
//     font-weight: 400 !important;
// }

