.custom-logo {
    padding: 8px;
    margin: auto;
    width: 300px;
    height: 150px;
    cursor: pointer;
    overflow: hidden;
    border-radius: var(--border-radius);
    border: 1px dashed rgba(145, 158, 171, 0.2);
}

.custom-logo-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: var(--border-radius);
    position: relative;
}

.custom-logo-container-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9;
    border-radius: var(--border-radius);
    position: absolute;
    color: rgb(145, 158, 171);
    background-color: rgba(145, 158, 171, 0.08);
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0ms;
}

.custom-logo-container-content-hidden {
    display: flex;
    flex-direction: column;
    gap: 8px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9;
    border-radius: var(--border-radius);
    position: absolute;
    color: rgb(145, 158, 171);
    background-color: rgba(145, 158, 171, 0.08);
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0ms;

    transition: 0.2s !important;

    .custom-logo-container-content-label {
        transition: 0.2s !important;
        display: none !important;
    }
    .custom-logo-container-content-icon {
        transition: 0.2s !important;
        display: none !important;
    }
}

.custom-logo-container-content-hidden:hover {
    background-color: rgb(33, 43, 54, 0.72);

    .custom-logo-container-content-label {
        transition: 0.2s !important;
        display: flex !important;
        color: white !important;
    }
    .custom-logo-container-content-icon {
        transition: 0.2s !important;
        display: flex !important;
        color: white !important;
    }
}

.custom-logo-container-content-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: bottom;
}

.custom-logo-container-content-icon {
    transition: 0.2s !important;
}
.custom-logo-container-content-label {
    transition: 0.2s !important;
    margin: 0px;
    line-height: 1.5;
    font-size: 0.75rem;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
}

.custom-logo-container-content:hover {
    opacity: 0.72 !important;
}
