.layout-banner {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
    width: 100%;
    height: 40px;
    padding: 0 2rem;
    background-color: var(--primary-color);
}

.layout-banner .layout-banner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - var(--scrollbar-width, 0px));
    height: 100%;
}

.layout-banner .layout-banner-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0;
}

.layout-banner .layout-banner-close {
    line-height: 1.5;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    transition: background-color .3s;
    margin-left: 0.5rem;
}

.layout-banner .layout-banner-text {
    // line-height: 1.5;
    // display: block;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // min-width: 0;
    color: var(--surface-f);
}

.layout-banner .layout-banner-link {
    // margin-left: 0.5rem;
    // line-height: 1.5;
    // white-space: nowrap;
    color: var(--surface-f);
}

.layout-banner .layout-banner-close {
    line-height: 1.5;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    transition: background-color .3s;
    margin-left: 0.5rem;
    color: var(--surface-f);
}