.task-card {
    /* padding: 0.5rem !important;
    background: white !important;
    border-radius: var(--border-radius);
    text-align: justify !important;
    text-justify: inter-word !important;
    border: 1px solid transparent !important;
    min-height: 4rem !important;
    max-height: 4rem !important;
    min-width: 100% !important;
    max-width: 525px !important; */



    background-color: var(--surface-card);
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #ced4da;
    border-radius: var(--border-radius);
    box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08) !important;
}

.task-card-selected {
    background: rgba(97, 101, 255, 0.1) !important;
}

.task-card:hover {
    background: rgba(97, 101, 255, 0.1) !important;
    border: 1px solid #89899D !important;
    cursor: auto;
}

.task-card-dragging {
    background: rgba(97, 101, 255, 0.1) !important;
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color) !important;
}

.task-card-lock-icon {
    color: var(--gray-400) !important;
}

/* .custom-task-checkbox {
    .p-checkbox {
        .p-checkbox-box {
            .p-highlight {
                border-color: var(--success-color) !important;
                background: var(--success-color) !important;
            }
        }
    }
} */

/* .p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--success-color) !important;
                background: var(--success-color) !important;
} */

.custom-task-checkbox .p-highlight {
    border-color: var(--success-color) !important;
    background: var(--success-color) !important;
}

.custom-task-checkbox .p-checkbox-box {
    border-radius: 50% !important;
}

.custom-task-checkbox .p-checkbox-box:hover {
    border-color: var(--success-color) !important;
}
.custom-task-checkbox .p-checkbox-box.p-focus {
    border-color: var(--success-color) !important;
}