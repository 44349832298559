.custom-file-preview {
    /* padding: 8px; */
    margin: auto;
    width: 64px;
    height: 64px;
    cursor: pointer;
    overflow: hidden;
    border-radius: var(--border-radius);
    border: 1px dashed rgba(145, 158, 171, 0.2);
}

.custom-file-preview-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: var(--border-radius);
    position: relative;
}

.custom-file-preview-container-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    -webkit-box-align: center;
    align-items: start;
    -webkit-box-pack: center;
    justify-content:end;
    top: 0px;
    left: 0px;
    padding-left: 0.3rem;
    padding-bottom: 0.3rem;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: var(--border-radius);
    position: absolute;
    color: rgb(145, 158, 171);
    background-color: rgba(145, 158, 171, 0.08);
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0ms;
}

.custom-file-preview-container-content-hidden {
    display: flex;
    flex-direction: column;
    gap: 8px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9;
    border-radius: var(--border-radius);
    position: absolute;
    color: rgb(145, 158, 171);
    background-color: rgba(145, 158, 171, 0.08);
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0ms;

    transition: 0.2s !important;

    .custom-file-preview-container-content-label {
        transition: 0.2s !important;
        display: none !important;
    }
    .custom-file-preview-container-content-icon {
        transition: 0.2s !important;
        display: none !important;
    }
}

.custom-file-preview-container-content-hidden:hover {
    background-color: rgb(33, 43, 54, 0.72);

    .custom-file-preview-container-content-label {
        transition: 0.2s !important;
        display: flex !important;
        color: white !important;
    }
    .custom-file-preview-container-content-icon {
        transition: 0.2s !important;
        display: flex !important;
        color: white !important;
    }
}

.custom-file-preview-container-content-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: bottom;
}

.custom-file-preview-container-content-icon {
    transition: 0.2s !important;
}
.custom-file-preview-container-content-label {
    transition: 0.2s !important;
    margin: 0px;
    line-height: 1.5;
    font-size: 0.75rem;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
}

.custom-file-preview-container-content:hover {
    opacity: 0.72 !important;
}


.custom-file-preview-container-content-close-icon {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    border-radius: 50%;
    overflow: visible;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.125rem;
    padding: 2px;
    top: 4px;
    right: 4px;
    position: absolute;
    color: rgb(255, 255, 255);
    background-color: rgba(22, 28, 36, 0.48);
    z-index: 1;
}