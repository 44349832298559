//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future

.input-time-custom {
    .p-inputtext {
        padding-top: 0.65rem;
        border: 1px solid transparent;
        text-align: center;
    }
    .p-inputtext:hover {
        border: 1px solid transparent;
    }

    .p-inputnumber {
        border: 1px solid #ced4da;
        border-radius: var(--border-radius);
        padding: 0.1rem;

        .p-inputnumber-button {
            .p-button-icon {
                color: var(--text-color);
                font-size: 1.2rem;
                font-weight: 600;
            }
        }
    }
}

.custom-time-picker {
    border: 1px solid #ced4da;
    border-radius: var(--border-radius);

    .p-button {
        .p-button-icon {
            color: var(--text-color);
            font-size: 1.1rem;
            font-weight: 600;
            border: none;
        }
    }

    .p-button:hover {
        border: none;
    }

    .p-inputtext {
        padding-top: 0.65rem;
        border: 1px solid transparent;
        text-align: center;
    }
    .p-inputtext:hover {
        border: 1px solid transparent;
    }
}

.custom-replace-inplace {
    .p-button.p-button-icon-only {
        background: none !important;
    }
    .p-button.p-button-icon-only:hover {
        background: none !important;
    }
}

.custom-text-input {
    border: none !important;
}

.custom-text-input::placeholder {
    color: #b4b4c7 !important;
}

.custom-text-input:focus {
    outline: none;
}

.p-rating-item {
    padding-left: 2px !important;
    padding-right: 2px !important;
}

.p-calendar {
    .p-button-icon-only {
        color: #6c757d;
        background: none !important;
        border: 1px solid #ced4da;
        border-right: none !important;
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
    }

    .p-button-icon-only:hover {
        color: #6c757d;
        background: none !important;
        border: 1px solid #ced4da;
        border-right: none !important;
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
    }
}

.page-layout {
    @media (min-width: 1024px) {
        padding: 5.5rem 4rem 2rem 4rem;
    }
    background-color: #F8F8FA !important;
    min-height: calc(100vh - 13rem) !important;
}

// .custom-organization-chart .p-organizationchart-table

.custom-organization-chart .p-organizationchart-table .p-organizationchart-node-content {
    padding: 0 !important;
    border: none !important;
    background: transparent !important;
}

.custom-organization-chart .p-organizationchart-table .p-organizationchart-node-content .p-component {
    margin: 0 !important;
}

div[disabled] {
    pointer-events: none;
    opacity: 0.7;
}

.custom-dropdown .p-inputtext {
    padding: 0.2rem 0.4rem !important;
}

.custom-title-inputtext.p-inputtext {
    transition: padding 0.2s, left 2s;
    // transition: max-width 1s linear;
    border: 1px solid transparent;
    padding-left: 0;
    background: transparent;
}
.custom-title-inputtext.p-inputtext:not(:focus):hover {
    border: 1px solid transparent;
}

.custom-title-inputtext.p-inputtext:active {
    border: 1px solid var(--primary-color) !important;
    padding-left: 0.75rem !important;
}

.custom-title-inputtext.p-inputtext:focus {
    padding-left: 0.75rem !important;
}

[data-amplify-authenticator] [data-amplify-router] {
    border: none;
    border-radius: 56px !important;
    background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%) !important;
    padding: 0.3rem !important;
}

[data-amplify-authenticator] [data-amplify-router] [data-amplify-router-content] {
    border-radius: 53px !important;
    background: white !important;
}

[data-amplify-authenticator] [data-amplify-form] {
    border-radius: 53px !important;
    background: white !important;
}

// .amplify-button {
//     color: #ffffff !important;
//     background: var(--primary-color) !important;
//     border: 1px solid transparent !important;
//     padding: 0.5rem 1rem !important;
//     font-size: 1rem !important;
//     transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
//     border-radius: var(--border-radius) !important;
// }

// .amplify-button--link {
//     background-color: transparent !important;
//     color: var(--primary-color) !important;
//     border-color: transparent !important;
// }
// .p-button.amplify-button--link:hover, .p-button.amplify-button--link:not(button):not(a):not(.p-disabled):hover {
//     background: rgba(99, 102, 241, 0.04);
//     color: var(--primary-color);
//     border-color: transparent;
//   }
//   .p-button.amplify-button--link:active, .p-button.amplify-button--link:not(button):not(a):not(.p-disabled):active {
//     background: rgba(99, 102, 241, 0.16);
//     color: var(--primary-color);
//     border-color: transparent;
//   }
//   .p-button.amplify-button--link {
//     color: #6c757d;
//   }
//   .p-button.amplify-button--link:hover, .p-button.amplify-button--link.p-button-plain:not(button):not(a):not(.p-disabled):hover {
//     background: #e9ecef;
//     color: #6c757d;
//   }
//   .p-button.amplify-button--link:active, .p-button.amplify-button--link.p-button-plain:not(button):not(a):not(.p-disabled):active {
//     background: #dee2e6;
//     color: #6c757d;
//   }

// // .amplify-button--primary {
// // }
// .amplify-button--primary .amplify-button:active {
//     border: 1px solid transparent !important;
// }
// .amplify-button--primary .amplify-button:hover,
// .amplify-button--primary:not(button):not(a):not(.p-disabled):hover {
//     background: var(--primary-color-light) !important;
//     color: #ffffff !important;
//     border: 1px solid transparent !important;
// }
// .amplify-button--primaryamplify-button:active,
// .amplify-button--primary:not(button):not(a):not(.p-disabled):active {
//     background: var(--primary-color) !important;
//     color: #ffffff !important;
//     border: 1px solid transparent !important;
// }

// .amplify-input {
//     font-family: var(--font-family) !important;
//     font-size: 1rem !important;
//     color: var(--secondary-color) !important;
//     background: #ffffff !important;
//     padding: 0.8rem 0.75rem !important;
// border: 1px solid #ced4da !important;
//     transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
//     appearance: none !important;
// border-radius: var(--border-radius) !important;
// border: none !important
// }

// .amplify-input:hover {
//     border: 1px solid var(--primary-color) !important;
//     // border-color: var(--primary-color) !important;
//     // border: none !important
// }
// .amplify-input:active {
//     border-color: var(--primary-color) !important;
//     // border: 1px solid var(--primary-color) !important;
//     // border: none !important;
// }
// .amplify-input:focus {
//     outline: 0 none !important;
//     outline-offset: 0 !important;
//     // border: none !important;
//     // border-color: var(--primary-color) !important;
//     border: 0.1px solid var(--primary-color) !important;
//     // border: none !important;
// }

// .amplify-textfield {
//     border: none !important;
// }
// .amplify-textfield:focus {
//     border: none !important;
// }

// .amplify-field {
//     border: none !important;
// }
// .amplify-field:focus {
//     border: none !important;
// }

// .amplify-tabs {
//     display: none;
// }

// .amplify-button--primary {
//     color: #ffffff !important;
//     background: var(--primary-color) !important;
//     border: 1px solid transparent !important;
//     padding: 0.5rem 1rem !important;
//     font-size: 1rem !important;
//     transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
//     border-radius: var(--border-radius) !important;
// }

// .amplify-button--link {
//     background-color: transparent;
//     color: var(--primary-color);
//     border-color: transparent;
// }



// .amplify-button--link:enabled:hover, .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
//     background: rgba(99, 102, 241, 0.04);
//     color: var(--primary-color);
//     border-color: transparent;
//   }
//   .amplify-button--link:enabled:active, .p-button.p-button-text:not(button):not(a):not(.p-disabled):active {
//     background: rgba(99, 102, 241, 0.16);
//     color: var(--primary-color);
//     border-color: transparent;
//   }
//   .amplify-button--link.p-button-plain {
//     color: #6c757d;
//   }
//   .amplify-button--link.p-button-plain:enabled:hover, .p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):hover {
//     background: #e9ecef;
//     color: #6c757d;
//   }
//   .amplify-button--link.p-button-plain:enabled:active, .p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):active {
//     background: #dee2e6;
//     color: #6c757d;
//   }