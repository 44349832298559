* {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: $fontSize;
}

body {
    font-family: var(--font-family);
    color: var(--text-color);

    // background-color: var(--miflow-no-white-background);
    background-color: #FFFFFF !important;
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    

    @media (min-width: 1024px) {
        overflow-x: hidden !important;
    }

    
}
@media (min-width: 1024px) {
    .layout-main:has(#process-constructor-page) {
        body {
            overflow: hidden !important;
        }
    }
}
a, button {
    text-decoration: none;
    color: var(--primary-color);
}

.layout-theme-light {
    background-color: var(--miflow-no-white-background);
}
