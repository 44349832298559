.layout-main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: margin-left $transitionDuration;
    min-height: calc(100vh - 13rem);
}

.layout-main {
    flex: 1 1 auto;

    @media (min-width: 1024px) {
    //     // max-height: calc(100vh - 13rem);
        min-height: calc(100vh - 6rem);
    //     min-height: 100% !important;
    }

    @media (min-width: 481px) and (max-width: 1024px) {
        min-height: calc(100vh - 15rem);
    }

    // @media (min-width: 320px) and (max-width: 480px) {
    //     // min-height: calc(100vh);
    // }
    
}


@media (max-width: 991px) {
    .layout-main-container {
        padding: 5.5rem 2rem 2rem 2rem;
    }
    // .layout-wrapper:has(#secondary-top-bar) {
    //     .layout-main-container{
    //         padding: 9rem 2rem 2rem 2rem;
    //     }
    // }
}

// .layout-wrapper:has(.layout-banner) {
//     .layout-topbar {
//         top: 40px
//     }
//     .layout-sidebar {
//         top: 96px
//     }
//     .layout-main-container {
//         margin-top: 4rem;
//     }
// }