.layout-topbar {
    position: fixed;
    // height: 3.2rem;
    height: 56px;
    z-index: 2;
    // left: 0;
    top: 0;
    @media (min-width: 1024px) {
        margin-left: 280px;
    }
    
    width: 100%;
    padding: 0 2rem;
    // background-color: var(--surface-card);
    transition: left $transitionDuration;
    display: flex;
    align-items: center;
    // border-bottom: 1px solid #b4b4c7;

    transition: all 0.3s;

    backdrop-filter: blur(6px);
    // background-color: rgba(255, 255, 255, 0.8);

    // box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);

    .layout-topbar-logo {
        display: flex;
        align-items: center;
        color: var(--surface-900);
        font-size: 1.5rem;
        font-weight: 500;
        width: 300px;
        border-radius: 12px;

        img {
            height: 5rem;
            margin-right: 0.5rem;
        }

        &:focus {
            @include focused();
        }
    }

    .layout-topbar-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: var(--text-color);
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        transition: background-color $transitionDuration;

        // &:hover {
        // color: var(--text-color);
        // background-color: var(--surface-hover);
        // }

        // &:focus {
        // @include focused();
        // }

        i {
            font-size: 1rem;
        }

        span {
            font-size: 1rem;
            display: none;
        }
    }

    .layout-menu-button {
        display: none;
        // margin-left: 2rem;
        z-index: 1200 !important;
    }

    .layout-topbar-menu-button {
        display: none;

        i {
            font-size: 1.25rem;
        }
    }

    .layout-topbar-menu-tabs {
        margin: 0 0 0 auto;
        padding: 0;
        list-style: none;
        display: flex;
        height: 56px;
        align-content: flex-end;
    }

    .layout-topbar-menu {
        margin: 0 0 0 auto;
        padding: 0;
        list-style: none;
        display: flex;

        .layout-topbar-button {
            margin-left: 1rem;
        }
    }
}

.layout-topbar {
    justify-content: space-between;

    .layout-topbar-logo {
        width: auto;
        order: 2;
    }

    .layout-menu-button {
        display: block;

        order: 1;
        // 

        z-index: 1200 !important;
        margin: 0;

        @media (min-width: 1024px) {
            background: white !important;
            left: -42px;
            border-radius: 50% !important;
            border: 1px dashed rgba(145, 158, 171, 0.2) !important;
            text-align: center !important;
        }
    }

    .layout-topbar-menu-button {
        display: inline-flex;
        margin-left: 0;
        order: 3;
        
    }

    .layout-topbar-menu-tabs {
        display: none;
    }

    .layout-main-container:has(#secondary-top-bar) {
        .layout-topbar-menu {
            // margin-top: 3.2rem;
            margin-top: 56px;
            padding-top: 56px;
        }
    }

    .layout-topbar-menu {
        margin-left: 0;
        position: absolute;
        flex-direction: column;
        background-color: var(--surface-overlay);
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        padding: 1rem;
        right: 2rem;
        top: 5rem;
        min-width: 15rem;
        display: none;
        -webkit-animation: scalein 0.15s linear;
        animation: scalein 0.15s linear;

        &.layout-topbar-menu-mobile-active {
            display: block;
        }

        .layout-topbar-button {
            margin-left: 0;
            display: flex;
            width: 100%;
            height: auto;
            justify-content: flex-start;
            border-radius: 12px;
            padding: 1rem;

            i {
                font-size: 1rem;
                margin-right: 0.5rem;
            }

            span {
                font-weight: medium;
                display: block;
            }
        }
    }
}

#secondary-top-bar:empty {
    display: none !important;
}
