// .layout-sidebar {
//     position: fixed;
//     // width: 100px;
//     width: 280px;
//     height: calc(100vh);
//     z-index: 200;
//     @media (max-width: 1024px) {
//         z-index: 2000;
//     }
//     overflow-y: auto;
//     user-select: none;
//     // top: 56px;
//     bottom: 0;
//     transition: transform $transitionDuration, left $transitionDuration;
//     background-color: var(--surface-overlay);
//     // border-right: 1px solid #b4b4c7;
//     transition: all $transitionDuration;
//     border-right: 1px dashed rgba(145, 158, 171, 0.2);
// }

// .layout-menu {
//     margin: 0;
//     padding: 0.5rem;
//     list-style-type: none;
//     user-select: none;

//     .layout-root-menuitem {
//         >.layout-menuitem-root-text {
//             font-size: .857rem;
//             text-transform: uppercase;
//             font-weight: 700;
//             color: var(--surface-900);
//             margin: .75rem 0;
//         }

//         > a {
//             display: none;
//         }
//     }

//     li.active-menuitem {
//         > a {
//             .layout-submenu-toggler {
//                 transform: rotate(-180deg);
//             }
//         }

//         > ul {
//             max-height: auto;
//         }
//     }

//     ul {
//         margin: 0;
//         padding: 0;
//         list-style-type: none;

//         a {
//             display: flex;
//             align-items: center;
//             position: relative;
//             outline: 0 none;
//             color: var(--text-color);
//             cursor: pointer;
//             padding: .75rem 1rem;
//             border-radius: $borderRadius;
//             transition: background-color $transitionDuration, box-shadow $transitionDuration;


//             .layout-menuitem-icon {
//                 margin-right: .5rem;
//             }

//             .layout-submenu-toggler {
//                 font-size: 75%;
//                 margin-left: auto;
//                 transition: transform $transitionDuration;
//             }

//             // &.rotated-icon {
//             //     .layout-menuitem-icon {
//             //         transform: rotate(90deg);
//             //     }
//             // }

//             &.active-route {
//                 font-weight: 700;
//                 color: var(--primary-color);
//             }

//             &:hover {
//                 background-color: var(--surface-hover);
//             }

//             &:focus {
//                 @include focused-inset();
//             }
//         }

//         ul {
//             overflow: hidden;
//             max-height: 0;
//             border-radius: $borderRadius;

//             li {
//                 a {
//                     margin-left: 1rem;
                    
//                 }

//                 li {
//                     a {
//                         margin-left: 2rem;
//                     }

//                     li {
//                         a {
//                             margin-left: 2.5rem;
//                         }

//                         li {
//                             a {
//                                 margin-left: 3rem;
//                             }

//                             li {
//                                 a {
//                                     margin-left: 3.5rem;
//                                 }

//                                 li {
//                                     a {
//                                         margin-left: 4rem;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

// .layout-submenu-enter-active {
//     max-height: 1000px !important;
//     transition: max-height 1s ease-in-out;
// }

// .layout-submenu-enter-done {
//     max-height: 1000px !important;
// }

// .layout-submenu-exit {
//     max-height: 1000px !important;
// }

// .layout-submenu-exit-active {
//     overflow: hidden;
//     max-height: 0 !important;
//     transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
// }


// ================================================================================================================================================================================================================
// ================================================================================================================================================================================================================
// ================================================================================================================================================================================================================
// ================================================================================================================================================================================================================
// ================================================================================================================================================================================================================
// ================================================================================================================================================================================================================
// ================================================================================================================================================================================================================

.layout-sidebar {
    position: fixed;
    width: 280px;
    height: calc(100vh);
    z-index: 1;
    @media (max-width: 769px) {
        z-index: 2;
    }
    overflow-y: auto;
    user-select: none;
    bottom: 0;
    transition: transform $transitionDuration, left $transitionDuration;
    background-color: var(--surface-overlay);
    transition: all $transitionDuration;
    border-right: 1px dashed rgba(145, 158, 171, 0.2);
    overflow-x: hidden !important;
}

.layout-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    

    li {
        &.layout-menuitem-category {
            margin-top: .75rem;

            &:first-child {
                margin-top: 0;
            }
        }

        padding-top: 0.5rem !important;

        .layout-menuitem-root-text {
            color: var(--secondary-color-extra-light);
            font-size: 12px !important;
            font-weight: 600 !important;
            text-transform: uppercase;
            padding: 16px 8px 8px 12px;
            cursor: pointer;
            font-weight: 700;
            line-height: 1.5;
            margin-bottom: .5rem;
        }

        .sub-icon {
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            margin-right: 5px;
            font-size: 35px !important;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
        }

        a {
            cursor: pointer;
            text-decoration: none;
            display: flex;
            align-items: center;
            color: var(--secondary-color-extra-light);
            font-weight: 500;
            font-size: 0.875rem;

            transition: color $transitionDuration;
            border-radius: var(--border-radius);
            padding: .75rem 1rem;
            transition: background-color .15s;

            svg {
                margin-right: 0.5rem !important;
            }

            span {
                margin-left: 0.5rem;
            }

            .menuitem-toggle-icon {
                margin-left: auto;
            }

            &:focus {
                @include focused-inset();
            }

            &:hover {
                background-color: var(--primary-color-super-light);
            }
            &.router-link-exact-active-root {
                font-weight: 800 !important;
                color: var(--primary-color);
                background-color: var(--primary-color-ultra-light);

                .sub-icon {
                    font-size: 50px !important;
                }
            }
            &.router-link-exact-active {
                font-weight: 600 !important;
                color: var(--primary-color);
                // color: var(--surface-900);
                
                // background-color: var(--primary-color-ultra-light);

                .sub-icon {
                    font-size: 50px !important;
                }
            }

            .p-badge {
                margin-left: auto;
            }
        }

        &.active-menuitem {
            > a {
                .menuitem-toggle-icon {
                    &:before {
                        content: '\e933';
                    }
                }
            }
        }

        ul {
            list-style-type: none;
            // margin: 0;
            padding: 0;

            &.layout-submenu-wrapper-enter {
                max-height: 0;
            }

            &.layout-submenu-wrapper-enter-active {
                overflow: hidden;
                max-height: 1000px;
                transition: max-height 1s ease-in-out;
            }

            &.layout-submenu-wrapper-enter-done {
                transform: none;
            }

            &.layout-submenu-wrapper-exit {
                max-height: 1000px;
            }

            &.layout-submenu-wrapper-exit-active {
                overflow: hidden;
                max-height: 0;
                transition: max-height 0.45s cubic-bezier(0.86, 0, 0.07, 1);
            }

            // ul {
            //     padding-left: 1rem;
            // }
        }
    }
}

// ================================================================================================================================================================================================================
// ================================================================================================================================================================================================================
// ================================================================================================================================================================================================================
// ================================================================================================================================================================================================================
// ================================================================================================================================================================================================================
// ================================================================================================================================================================================================================
// ================================================================================================================================================================================================================

// .layout-sidebar {
//     position: fixed;
//     // width: 100px;
//     width: 280px;
//     height: calc(100vh);
//     z-index: 200;
//     @media (max-width: 1024px) {
//         z-index: 2000;
//     }
//     overflow-y: auto;
//     user-select: none;
//     // top: 56px;
//     bottom: 0;
//     transition: transform $transitionDuration, left $transitionDuration;
//     background-color: var(--surface-overlay);
//     // border-right: 1px solid #b4b4c7;
//     transition: all $transitionDuration;
//     border-right: 1px dashed rgba(145, 158, 171, 0.2);
// }

// @media (min-width: 1024px) {
//     .layout-sidebar {
//         flex-shrink: 0;
//         width: 280px;
//     }
// }

// .layout-sidebar-item-icon {
//     height: 3rem;
// }

// .layout-menu {
//     list-style-type: none;
//     margin: 0;
//     padding: 0;
//     display: block;

//     li {

        

//         // padding-top: 1.5rem;

//         // width: 100%;
//         // height: 6.5rem;

//         a {
//             // text-decoration: none;
//             // color: var(--text-color);
//             // margin: 0px;
//             // font: inherit;
//             // text-decoration: none;
//             // color: inherit;
            
//             -webkit-tap-highlight-color: transparent;
//             background-color: transparent;
//             outline: 0px;
//             border: 0px;
//             margin: 0px 0px 4px;
//             cursor: pointer;
//             user-select: none;
//             vertical-align: middle;
//             appearance: none;
//             display: flex;
//             -webkit-box-flex: 1;
//             flex-grow: 1;
//             -webkit-box-pack: start;
//             justify-content: flex-start;
//             -webkit-box-align: center;
//             align-items: center;
//             position: relative;
//             text-decoration: none;
//             min-width: 0px;
//             box-sizing: border-box;
//             text-align: left;
//             transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//             border-radius: 8px;
//             color: rgb(99, 115, 129);
//             padding: 4px 8px 4px 12px;
//             min-height: 44px;
//         }

//         &.active-menuitem {
//             > a {
//                 .menuitem-toggle-icon {
//                     &:before {
//                         content: "\e933";
//                     }
//                 }
//             }
//         }

//         ul {
//             list-style-type: none;
//             margin: 0;
//             padding: 0;

//             &.layout-submenu-wrapper-enter {
//                 max-height: 0;
//             }

//             &.layout-submenu-wrapper-enter-active {
//                 overflow: hidden;
//                 max-height: 1000px;
//                 transition: max-height 1s ease-in-out;

//                 -webkit-tap-highlight-color: transparent;
//                 outline: 0px;
//                 border: 0px;
//                 margin: 0px 0px 4px;
//                 cursor: pointer;
//                 user-select: none;
//                 vertical-align: middle;
//                 appearance: none;
//                 display: flex;
//                 -webkit-box-flex: 1;
//                 flex-grow: 1;
//                 -webkit-box-pack: start;
//                 justify-content: flex-start;
//                 -webkit-box-align: center;
//                 align-items: center;
//                 position: relative;
//                 text-decoration: none;
//                 min-width: 0px;
//                 box-sizing: border-box;
//                 text-align: left;
//                 transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//                 border-radius: 8px;
//                 color: rgb(0, 167, 111);
//                 padding: 4px 8px 4px 12px;
//                 min-height: 44px;


//             }

//             &.layout-submenu-wrapper-enter-done {
//                 transform: none;
//             }

//             &.layout-submenu-wrapper-exit {
//                 max-height: 1000px;
//             }

//             &.layout-submenu-wrapper-exit-active {
//                 overflow: hidden;
//                 max-height: 0;
//                 transition: max-height 0.45s cubic-bezier(0.86, 0, 0.07, 1);
//             }

//             ul {
//                 padding-left: 1rem;
//             }
//         }
//     }
// }
