.absence-checkbox.FC959A .p-checkbox-box.p-highlight {
    border: 1px solid #B4B4C7 !important;
    background: #FC959A !important;
}
.absence-checkbox.FAD797 .p-checkbox-box.p-highlight {
    border: 1px solid #B4B4C7 !important;
    background: #FAD797 !important;
}
.absence-checkbox.a8ADCBD .p-checkbox-box.p-highlight {
    border: 1px solid #B4B4C7 !important;
    background: #8ADCBD !important;
}
.absence-checkbox.FFA979 .p-checkbox-box.p-highlight {
    border: 1px solid #B4B4C7 !important;
    background: #FFA979 !important;
}
.absence-checkbox.BDACFF .p-checkbox-box.p-highlight {
    border: 1px solid #B4B4C7 !important;
    background: #BDACFF !important;
}
.absence-checkbox.a8285F8 .p-checkbox-box.p-highlight {
    border: 1px solid #B4B4C7 !important;
    background: #8285F8 !important;
}
.absence-checkbox.B4B4C7 .p-checkbox-box.p-highlight {
    border: 1px solid #B4B4C7 !important;
    background: #B4B4C7 !important;
}




.absence-checkbox.FC959A .p-checkbox-box {
    border: 1px solid #B4B4C7 !important;
    background: #FC959A !important;
}
.absence-checkbox.FAD797 .p-checkbox-box {
    border: 1px solid #B4B4C7 !important;
    background: #FAD797 !important;
}
.absence-checkbox.a8ADCBD .p-checkbox-box {
    border: 1px solid #B4B4C7 !important;
    background: #8ADCBD !important;
}
.absence-checkbox.FFA979 .p-checkbox-box {
    border: 1px solid #B4B4C7 !important;
    background: #FFA979 !important;
}
.absence-checkbox.BDACFF .p-checkbox-box {
    border: 1px solid #B4B4C7 !important;
    background: #BDACFF !important;
}
.absence-checkbox.a8285F8 .p-checkbox-box {
    border: 1px solid #B4B4C7 !important;
    background: #8285F8 !important;
}
.absence-checkbox.B4B4C7 .p-checkbox-box {
    border: 1px solid #B4B4C7 !important;
    background: #B4B4C7 !important;
}