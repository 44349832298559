@media (min-width: 992px) {
    .layout-wrapper {
        &.layout-overlay {
            .layout-main-container {
                margin-left: 0;
                padding-left: 3rem;
            }

            .layout-sidebar {
                transform: translateX(-100%);
                left: 0;
                top: 0;
                height: 100vh;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &.layout-overlay-sidebar-active {
                .layout-sidebar {
                    transform: translateX(0);
                }
            }
        }

        &.layout-static {
            .layout-main-container {
                // margin-left: 75px;
                margin-left: 280px;
            }

            .layout-topbar {
                width: calc(100% - 280px) !important;
            }

            // &.layout-static-sidebar-inactive {
            //     .layout-sidebar {
            //         transform: translateX(-100%);
            //         left: 0;
            //     }
                
            //     .layout-main-container {
            //         margin-left: 0;
            //         // padding-left: 0rem;
            //     }
            // }
            &.layout-static-sidebar-medium {

                .layout-menu {
                    padding-right: 0.3rem !important;
                    padding-left: 0.3rem !important;

                    .layout-menuitem-child {
                        display: none !important;
                    }

                    li {
                        .layout-menuitem-root-text {
                            display: none !important;
                        }

                        

                        a {
                            span {
                                margin-left: 0rem;
                                padding-top: 0.5rem;
                            }

                            .menuitem-toggle-icon {
                                display: none !important;
                            }
                            font-size: 10px;
                            display: flex;
                            flex-direction: column;
                            
                            svg {
                                margin-right: 0rem !important;
                            }
                            
                            img {
                                // width: rem !important;
                                // height: 3rem !important;
                                margin-right: 0rem !important;
                            }
                            // span {
                            //     // min-width: 10rem !important;
                            // }
                        }
                    }
                }

                .layout-sidebar {
                    // transform: translateX(-100%);
                    // left: 0;
                    width: 88px;
                }

                .layout-topbar {
                    margin-left: 88px;
                    width: calc(100% - 88px) !important;
                }
                
                .layout-main-container {
                    margin-left: 0;
                    margin-left: 88px;
                }
            }
        }

        // &.layout-medium {
        //     .layout-main-container {
        //         margin-left: 75px;
        //     }

        //     &.layout-static-sidebar-inactive {
        //         .layout-sidebar {
        //             transform: translateX(-100%);
        //             left: 0;
        //         }

        //         .layout-main-container {
        //             margin-left: 0;
        //             padding-left: 2rem;
        //         }
        //     }
        // }

        .layout-mask {
            display: none;
        }
    }
}

@media (max-width: 991px) {
    .layout-wrapper {
        .layout-main-container {
            margin-left: 0;
            padding-left: 2rem;
        }

        .layout-sidebar {
            transform: translateX(-100%);
            left: 0;
            top: 0;
            height: 100vh;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .layout-mask {
            z-index: 998;
            background-color: var(--mask-bg);

            &.layout-mask-enter-from,
            &.layout-mask-leave-to {
                background-color: transparent;
            }
        }

        &.layout-mobile-sidebar-active {
            .layout-sidebar {
                transform: translateX(0);
            }

            .layout-mask {
                display: block;
            }
        }
    }

    // .body-overflow-hidden {
    //     overflow: hidden;
    // }
}
