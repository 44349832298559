.vertical-tab-menu-menu-item {
    border-radius: 8px !important;
}

.vertical-tab-menu-menu-item-selected {
    border-radius: 8px !important;
    background-color: rgba(97, 101, 255, 0.1) !important;
}


.vertical-tab-menu-menu-item:hover {
    background-color: rgba(97, 101, 255, 0.1) !important;
}