.user-track-custom-single-line-calendar .p-inputtext{
    border: none !important;
    border-radius: 0 !important;
    padding-right: 0.1rem !important;
    border-right: 1px solid #ced4da !important;
    border-left: 1px solid #ced4da !important;
    background: none !important;
    text-align: center !important;
}

.user-track-custom-calendar-box {
    border: 1px solid #ced4da;
    border-radius: var(--border-radius);
    margin: 0 !important;
    padding: 0 !important;
    background-color: var(--surface-a) !important;
}

.user-track-custom-incurred-hour-box {
    background: var(--secondary-color-mega-light);
    padding-left: 0.4rem !important;
    padding-right: 0.4rem !important;
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
    border-radius: var(--border-radius);
    text-align: center !important;
    max-width: 8rem !important;
    min-width: 8rem !important;
    justify-content: end !important;
}

.user-track-custom-time-picker {
    border: 1px solid #ced4da;
    border-radius: var(--border-radius);
    min-width: 8rem !important;
    max-width: 8rem !important;

    .p-button {
        .p-button-icon {
            color: var(--text-color);
            font-size: 1.1rem;
            font-weight: 600;
            border: none;
        }
    }

    .p-button:hover {
        border: none;
    }

    .p-inputtext {
        padding-top: 0.65rem;
        border: 1px solid transparent;
        text-align: right;
    }
    .p-inputtext:hover {
        border: 1px solid transparent;
    }
}

.user-track-column-header .p-column-header-content {
    min-width: 100% !important;
    justify-content: end !important;
    padding-right: 0.5rem !important;
}

.user-track-column-header-names .p-column-header-content{
    min-width: 100% !important;
    justify-content: center !important;
}