.custom-kanban-list-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: flex-start;
    padding: 2px;
    height: 100%;
}


.custom-kanban-list-container-column {
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    background-image: none;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 16px;
    background-color: var(--miflow-no-white-v2-background);
    min-width: 320px;
    border: 1px solid var(--surface-300);
    
}

.custom-kanban-list-container-add-section-container {
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    background-image: none;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 16px;
    width: 320px;
}